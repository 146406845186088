<template>
    <div class="measure-box">
        <div class="inner">
            <div
                v-if="headerLabel"
                class="header">
                <div class="label">{{ headerLabel }}</div>
            </div>
            <div class="body">
                <div class="label">{{ headerValue }}</div>
            </div>
            <div
                class="footer"
                v-if="showChainValue">
                <div class="label">环比</div>
                <div
                    class="value"
                    v-if="headerChainValue === 0">---
                </div>
                <div
                    class="value tc-danger"
                    v-if="headerChainValue > 0">{{ headerChainValue }}% ▲
                </div>
                <div
                    class="value tc-success"
                    v-if="headerChainValue < 0">{{ headerChainValue }}% ▼
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MeasureBox',
    props: {
        dataset: {
            type: Object,
            default: () => ({}),
        },
        showChainValue: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        headerLabel() {
            return this.dataset.label;
        },
        headerValue() {
            return this.dataset.value;
        },
        headerChainValue() {
            return this.dataset.chainValue;
        },
    },
};
</script>

<style scoped lang="scss">
.measure-box {
    padding: $padding;

    > .inner {
        > .header {
            > .label {
                font-size: 12px;
                color: #8a93a7;
            }
        }

        > .body {
            margin-top: 10px;

            > .label {
                font-size: 26px;
                line-height: 1;
            }
        }

        > .footer {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            font-size: 12px;
            margin-top: 10px;

            > .label {
                color: #8a93a7;
                margin-right: 5px;

                &:after {
                    content: ':';
                }
            }
        }
    }

    &:hover {
        background: #f4f8ff;
        box-shadow: 0 0 0 1.5px inset rgb(208 223 255);
        border-radius: 10px;
    }
}
</style>
