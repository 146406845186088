<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page>
        <!-- 内容 -->
        <div
            class="body"
            slot="body"
        >
            <el-card
                class="ma-b"
                size="small"
                shadow="never"
            >
                <div
                    slot="header"
                    class="clearfix"
                >
                    <div class="dp-fx fx-row fx-ai-c">
                        <span>整体情况</span>
                        <el-popover
                            class="ma-l"
                            placement="top-start"
                            width="800"
                            trigger="hover">
                            <div slot>
                                注：实时数据仅供参考，准确数据以天级数据为准
                                <br>
                                实收金额：今日凌晨至更新时间，在应收金额的基础上，扣除掉积分余额，后台加减价后实际支付的金额（不含充值订单）
                                <br>
                                浏览量：今日凌晨至更新时间，店铺页面被浏览的次数。每打开一个页面或每刷新一次页面都记录1次
                                <br>
                                访客量：今日凌晨至更新时间，进入店铺的去重访客量
                                <br>
                                下单金额：今日凌晨至更新时间，提交订单中的商品小计总金额（不含充值订单）
                                <br>
                                下单单数：今日凌晨至更新时间，提交的订单数（货到付款以确认收货计入统计，自提以后台核销成功后计入统计；不含充值订单）
                                <br>
                                支付人数：今日凌晨至更新时间，在店铺中支付的订单的人数（不含充值订单）
                                <br>
                                支付单数：今日凌晨至更新时间，成功支付的订单数（货到付款以确认收货计入统计，自提以后台核销成功后计入统计；不含充值订单）
                                <br>
                                客单价：今日凌晨至更新时间，每个客户平均应付金额（=总应收金额/支付客户数）
                                <br>
                                新增客户数：今日凌晨至更新时间，店铺新增的客户数。有以下2种任1行为都是新客户：1、首次公众号或小程序主动授权 2、首次关注公众号
                                <br>
                                售后成功金额：今日凌晨至更新时间，在店铺中售后成功的金额
                            </div>
                            <i
                                class="el-icon-warning-outline"
                                slot="reference"></i>
                        </el-popover>
                    </div>
                </div>
                <el-row
                    justify="space-between"
                >
                    <el-col
                        :span="4"
                    >
                        <MeasureBox
                            :dataset="{
                                label:'实收金额',
                                value:shopSaleStat.orderAmount,
                            }"
                            :show-chain-value="false"
                        />
                    </el-col>
                    <el-col
                        :span="4"
                    >
                        <MeasureBox
                            :dataset="{
                                label:'浏览量',
                                value:shopSaleStat.pv,
                            }"
                            :show-chain-value="false"
                        />
                    </el-col>
                    <el-col
                        :span="4"
                    >
                        <MeasureBox
                            :dataset="{
                                label:'访客量',
                                value:shopSaleStat.uv,
                            }"
                            :show-chain-value="false"
                        />
                    </el-col>
                    <el-col
                        :span="4"
                    >
                        <MeasureBox
                            :dataset="{
                                label:'下单单数',
                                value:shopSaleStat.createOrderNum,
                            }"
                            :show-chain-value="false"
                        />
                    </el-col>
                    <el-col
                        :span="4"
                    >
                        <MeasureBox
                            :dataset="{
                                label:'下单金额',
                                value:shopSaleStat.createOrderAmount,
                            }"
                            :show-chain-value="false"
                        />
                    </el-col>
                    <el-col
                        :span="4"
                    >
                        <MeasureBox
                            :dataset="{
                                label:'支付人数',
                                value:shopSaleStat.orderBuyerNum,
                            }"
                            :show-chain-value="false"
                        />
                    </el-col>
                    <el-col
                        :span="4"
                    >
                        <MeasureBox
                            :dataset="{
                                label:'支付单数',
                                value:shopSaleStat.orderNum,
                            }"
                            :show-chain-value="false"
                        />
                    </el-col>
                    <el-col
                        :span="4"
                    >
                        <MeasureBox
                            :dataset="{
                                label:'客单价',
                                value:shopSaleStat.customerUnitPrice,
                            }"
                            :show-chain-value="false"
                        />
                    </el-col>
                    <el-col
                        :span="4"
                    >
                        <MeasureBox
                            :dataset="{
                                label:'新增客户数',
                                value:shopSaleStat.registerBuyerNum,
                            }"
                            :show-chain-value="false"
                        />
                    </el-col>
                    <el-col
                        :span="4"
                    >
                        <MeasureBox
                            :dataset="{
                                label:'售后成功金额',
                                value:shopSaleStat.refundAmount,
                            }"
                            :show-chain-value="false"
                        />
                    </el-col>
                </el-row>
            </el-card>
            <el-card
                class="ma-b"
                size="small"
                shadow="never"
            >
                <div
                    slot="header"
                    class="clearfix"
                >
                    <div class="dp-fx fx-row fx-ai-c">
                        <span>分时段分析</span>
                    </div>
                </div>
                <el-row
                    justify="space-between"
                >
                    <el-col
                        :span="12"
                    >
                        浏览量
                        <ECharts
                            class="echarts-demo"
                            :options="echartsOptions1"
                        />
                    </el-col>
                    <el-col
                        :span="12"
                    >
                        访客量
                        <ECharts
                            class="echarts-demo"
                            :options="echartsOptions2"
                        />
                    </el-col>
                    <el-col
                        :span="24"
                    >
                        <el-divider></el-divider>
                    </el-col>
                    <el-col
                        :span="12"
                    >
                        支付单数
                        <ECharts
                            class="echarts-demo"
                            :options="echartsOptions3"
                        />
                    </el-col>
                    <el-col
                        :span="12"
                    >
                        应收金额
                        <ECharts
                            class="echarts-demo"
                            :options="echartsOptions4"
                        />
                    </el-col>
                </el-row>
            </el-card>
            <el-card
                class="ma-b"
                size="small"
                shadow="never"
            >
                <div
                    slot="header"
                    class="clearfix"
                >
                    <div class="dp-fx fx-row fx-ai-c">
                        <span>渠道分析</span>
                    </div>
                </div>
                <el-row
                    justify="space-between"
                >
                    <el-col
                        :span="12"
                    >
                        支付单数
                        <ECharts
                            class="echarts-demo"
                            :options="echartsOptions5"
                        />
                    </el-col>
                    <el-col
                        :span="12"
                    >
                        支付金额
                        <ECharts
                            class="echarts-demo"
                            :options="echartsOptions6"
                        />
                    </el-col>
                </el-row>
            </el-card>
            <el-card
                class="ma-b"
                size="small"
                shadow="never"
            >
                <div
                    slot="header"
                    class="clearfix"
                >
                    <div class="dp-fx fx-row fx-ai-c">
                        <span>交易转化漏斗</span>
                    </div>
                </div>
                <el-row
                    justify="space-between"
                >
                    <el-col
                        :span="24"
                    >
                        <ECharts
                            class="echarts-demo"
                            :options="echartsOptions7"
                        />
                    </el-col>
                </el-row>
            </el-card>
            <el-card
                class="ma-b"
                size="small"
                shadow="never"
            >
                <div
                    slot="header"
                    class="clearfix"
                >
                    <div class="dp-fx fx-row fx-ai-c">
                        <span>商品访问加购排行</span>
                    </div>
                </div>
                <el-row
                    justify="space-between"
                >
                    <el-col
                        :span="24"
                    >
                        <el-table
                            stripe
                            border
                            size="small"
                            :data="goodsVisitLogList"
                            style="width: 100%"
                        >
                            <el-table-column
                                prop="goodsSn"
                                label="商品编码"
                                min-width="100"
                            />
                            <el-table-column
                                prop="goodsName"
                                label="商品名称"
                                min-width="100"
                            />
                            <el-table-column
                                prop="uv"
                                label="访客量"
                                min-width="50"
                            />
                            <el-table-column
                                prop="addCartGoodsNum"
                                label="加购件数"
                                min-width="50"
                            />
                        </el-table>
                        <div class="ma-t ta-r">
                            <pagination
                                v-model="goodsVisitLogPage"
                                @input="onQueryGoodsVisitLog"
                            />
                        </div>
                    </el-col>
                </el-row>
            </el-card>
            <el-card
                class="ma-b"
                size="small"
                shadow="never"
            >
                <div
                    slot="header"
                    class="clearfix"
                >
                    <div class="dp-fx fx-row fx-ai-c">
                        <span>商品销售排行</span>
                    </div>
                </div>
                <el-row
                    justify="space-between"
                >
                    <el-col
                        :span="24"
                    >
                        <el-table
                            stripe
                            border
                            size="small"
                            :data="goodsSaleStatList"
                            style="width: 100%"
                        >
                            <el-table-column
                                prop="goodsSn"
                                label="商品编码"
                                min-width="100"
                            />
                            <el-table-column
                                prop="goodsName"
                                label="商品名称"
                                min-width="100"
                            />
                            <el-table-column
                                prop="orderGoodsNum"
                                label="支付件数"
                                min-width="50"
                            />
                        </el-table>
                        <div class="ma-t ta-r">
                            <pagination
                                v-model="goodsSaleStatPage"
                                @input="onQueryGoodsSaleStat"
                            />
                        </div>
                    </el-col>
                </el-row>
            </el-card>
            <el-card
                class="ma-b"
                size="small"
                shadow="never"
            >
                <div
                    slot="header"
                    class="clearfix"
                >
                    <div class="dp-fx fx-row fx-ai-c">
                        <span>门店排行</span>
                    </div>
                </div>
                <el-row
                    justify="space-between"
                >
                    <el-col
                        :span="24"
                    >
                        <el-table
                            stripe
                            border
                            size="small"
                            :data="storeSaleStatList"
                            style="width: 100%"
                        >
                            <el-table-column
                                prop="storeSn"
                                label="门店编码"
                                min-width="100"
                            />
                            <el-table-column
                                prop="storeName"
                                label="门店名称"
                                min-width="100"
                            />
                            <el-table-column
                                prop="orderNum"
                                label="订单数"
                                min-width="50"
                            />
                            <el-table-column
                                prop="orderAmount"
                                label="订单金额"
                                min-width="50"
                            />
                        </el-table>
                    </el-col>
                </el-row>
            </el-card>
        </div>
    </page>
</template>

<script>
import MeasureBox from '@/components/MeasureBox';

export default {
    name: 'GoodsSaleStatIndex',
    components: { MeasureBox },
    data() {
        return {
            queryFormModel: {
                type: '1',
                statDateTime: '',
                statDateDay: [],
                statDateWeek: '',
                statDateMonth: [],
            },
            queryFormRule: {
                statDateTime: {
                    required: true,
                    message: '请选择统计时间',
                },
                statDateDay: {
                    type: 'array',
                    required: true,
                    message: '请选择统计时间',
                },
                statDateWeek: {
                    required: true,
                    message: '请选择统计时间',
                },
                statDateMonth: {
                    type: 'array',
                    required: true,
                    message: '请选择统计时间',
                },
            },
            // 表格数据
            tableData: [],
            pickerOptions: {
                shortcuts: [
                    {
                        text: '最近一周',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 8);
                            end.setTime(end.getTime() - 3600 * 1000 * 24 * 1);
                            picker.$emit('pick', [start, end]);
                        },
                    },
                    {
                        text: '最近一个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 31);
                            end.setTime(end.getTime() - 3600 * 1000 * 24 * 1);
                            picker.$emit('pick', [start, end]);
                        },
                    },
                    {
                        text: '最近三个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 91);
                            end.setTime(end.getTime() - 3600 * 1000 * 24 * 1);
                            picker.$emit('pick', [start, end]);
                        },
                    },
                ],
                disabledDate: this.disabledDate,
            },
            pickerOptions1: {
                disabledDate: this.disabledDate1,
                firstDayOfWeek: 1,
            },
            pickerOptions2: {
                disabledDate: this.disabledDate2,
            },
            shopSaleStat: {},
            shopSaleStatList: [],
            browserTypeSaleStatList: [],
            goodsVisitLogList: [],
            goodsVisitLogPage: {},
            goodsSaleStatList: [],
            goodsSaleStatPage: {},
            storeSaleStatList: [],
        };
    },
    computed: {
        echartsOptions1() {
            const data1 = [];
            const data2 = [];
            const date = [];
            this.shopSaleStatList.forEach(item => {
                date.push(item.statDate);
                data1.push(item.pv);
                data2.push(item.pvLast);
            });
            return {
                tooltip: {
                    trigger: 'axis',
                },
                legend: {
                    data: ['昨日', '今日'],
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true,
                },
                toolbox: {
                    feature: {
                        saveAsImage: {},
                    },
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: date,
                },
                yAxis: {
                    type: 'value',
                },
                series: [
                    {
                        name: '昨日',
                        type: 'line',
                        data: data2,
                    },
                    {
                        name: '今日',
                        type: 'line',
                        data: data1,
                    },
                ],
            };
        },
        echartsOptions2() {
            const data1 = [];
            const data2 = [];
            const date = [];
            this.shopSaleStatList.forEach(item => {
                date.push(item.statDate);
                data1.push(item.uv);
                data2.push(item.uvLast);
            });
            return {
                tooltip: {
                    trigger: 'axis',
                },
                legend: {
                    data: ['昨日', '今日'],
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true,
                },
                toolbox: {
                    feature: {
                        saveAsImage: {},
                    },
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: date,
                },
                yAxis: {
                    type: 'value',
                },
                series: [
                    {
                        name: '昨日',
                        type: 'line',
                        data: data2,
                    },
                    {
                        name: '今日',
                        type: 'line',
                        data: data1,
                    },
                ],
            };
        },
        echartsOptions3() {
            const data1 = [];
            const data2 = [];
            const date = [];
            this.shopSaleStatList.forEach(item => {
                date.push(item.statDate);
                data1.push(item.orderNum);
                data2.push(item.orderNumLast);
            });
            return {
                tooltip: {
                    trigger: 'axis',
                },
                legend: {
                    data: ['昨日', '今日'],
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true,
                },
                toolbox: {
                    feature: {
                        saveAsImage: {},
                    },
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: date,
                },
                yAxis: {
                    type: 'value',
                },
                series: [
                    {
                        name: '昨日',
                        type: 'line',
                        data: data2,
                    },
                    {
                        name: '今日',
                        type: 'line',
                        data: data1,
                    },
                ],
            };
        },
        echartsOptions4() {
            const data1 = [];
            const data2 = [];
            const date = [];
            this.shopSaleStatList.forEach(item => {
                date.push(item.statDate);
                data1.push(item.orderAmount);
                data2.push(item.orderAmountLast);
            });
            return {
                tooltip: {
                    trigger: 'axis',
                },
                legend: {
                    data: ['昨日', '今日'],
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true,
                },
                toolbox: {
                    feature: {
                        saveAsImage: {},
                    },
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: date,
                },
                yAxis: {
                    type: 'value',
                },
                series: [
                    {
                        name: '昨日',
                        type: 'line',
                        data: data2,
                    },
                    {
                        name: '今日',
                        type: 'line',
                        data: data1,
                    },
                ],
            };
        },
        echartsOptions5() {
            const dataList = [];
            const title = [];
            this.browserTypeSaleStatList.forEach(item => {
                title.push(item.browserTypeName);
                dataList.push({ value: item.orderNum, name: item.browserTypeName });
            });
            return {
                title: {
                    text: '支付单数',
                    left: 'center',
                },
                tooltip: {
                    trigger: 'item',
                    formatter: '{a} <br/>{b} : {c} ({d}%)',
                },
                legend: {
                    orient: 'vertical',
                    left: 'left',
                    data: title,
                },
                series: [
                    {
                        name: '支付单数',
                        type: 'pie',
                        radius: '55%',
                        center: ['50%', '60%'],
                        data: dataList,
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)',
                            },
                        },
                    },
                ],
            };
        },
        echartsOptions6() {
            const dataList = [];
            const title = [];
            this.browserTypeSaleStatList.forEach(item => {
                title.push(item.browserTypeName);
                dataList.push({ value: item.orderAmount, name: item.browserTypeName });
            });
            return {
                title: {
                    text: '支付金额',
                    left: 'center',
                },
                tooltip: {
                    trigger: 'item',
                    formatter: '{a} <br/>{b} : {c} ({d}%)',
                },
                legend: {
                    orient: 'vertical',
                    left: 'left',
                    data: title,
                },
                series: [
                    {
                        name: '支付金额',
                        type: 'pie',
                        radius: '55%',
                        center: ['50%', '60%'],
                        data: dataList,
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)',
                            },
                        },
                    },
                ],
            };
        },
        echartsOptions7() {
            return {
                tooltip: {
                    trigger: 'item',
                    formatter: '{a} <br/>{b} : {c}',
                },
                toolbox: {
                    feature: {
                        dataView: { readOnly: false },
                        restore: {},
                        saveAsImage: {},
                    },
                },
                legend: {
                    data: ['访客量', '下单人数', '支付人数'],
                },
                series: [
                    {
                        name: '漏斗图',
                        type: 'funnel',
                        left: '10%',
                        top: 60,
                        // x2: 80,
                        bottom: 60,
                        width: '80%',
                        // height: {totalHeight} - y - y2,
                        minSize: '0%',
                        maxSize: '100%',
                        sort: 'descending',
                        gap: 2,
                        label: {
                            show: true,
                            position: 'inside',
                        },
                        labelLine: {
                            length: 10,
                            lineStyle: {
                                width: 1,
                                type: 'solid',
                            },
                        },
                        itemStyle: {
                            borderColor: '#fff',
                            borderWidth: 1,
                        },
                        emphasis: {
                            label: {
                                fontSize: 20,
                            },
                        },
                        data: [
                            { value: this.shopSaleStat.uv, name: '访客量' },
                            { value: this.shopSaleStat.createOrderBuyerNum, name: '下单人数' },
                            { value: this.shopSaleStat.orderBuyerNum, name: '支付人数' },
                        ],
                    },
                ],
            };
        },
    },
    methods: {
        /* -- 方法 -- */
        // 请求列表数据
        disabledDate(date) {
            return new Date().getTime() < new Date(date).getTime() + 3600 * 1000 * 24;
        },
        disabledDate1(date) {
            const week = new Date().getDay();
            const minus = week ? week - 1 : 6;
            return new Date().getTime() < new Date(date).getTime() + 3600 * 1000 * 24 * (minus + 1);
        },
        disabledDate2(date) {
            const nowDate = new Date();
            const dt = new Date(date);
            const year = `${nowDate.getFullYear()}}`;
            const month = `${nowDate.getMonth()}`.padStart(2, '0');
            const year1 = `${dt.getFullYear()}}`;
            const month1 = `${dt.getMonth()}`.padStart(2, '0');
            return `${year}${month}` <= `${year1}${month1}`;
        },
        init() {
            this.$api.St.ShopSaleStat.statDetail({ ...this.queryFormModel }).then(json => {
                const res = json.data.data;
                this.shopSaleStat = res.shopSaleStat;
            });
            this.$api.St.ShopSaleStat.findStatDetailList({ ...this.queryFormModel }).then(json => {
                const res = json.data.data;
                this.shopSaleStatList = res.shopSaleStatList;
            });
            this.$api.St.BrowserTypeSaleStat.findStatDetailList({ ...this.queryFormModel }).then(json => {
                const res = json.data.data;
                this.browserTypeSaleStatList = res || [];
            });
            this.onQueryGoodsVisitLog();
            this.onQueryGoodsSaleStat();
            this.onQueryStoreSaleStat();
        },
        getYeasterDay() {
            const time = new Date().getTime() - 24 * 60 * 60 * 1000;
            let yesterday = new Date(time);
            // const month = yesterday.getMonth();
            // const day = yesterday.getDate();
            yesterday = `${yesterday.getFullYear()}-${
                yesterday.getMonth() > 9 ? yesterday.getMonth() + 1 : `0${yesterday.getMonth() + 1}`
            }-${yesterday.getDate() > 9 ? yesterday.getDate() : `0${yesterday.getDate()}`}`;
            return yesterday;
        },
        getLastMonth() {
            const date = new Date();
            let lastMonthDate = '';
            let month = date.getMonth();
            const year = date.getFullYear();
            // 上个月小于1则证明跨年，年份减1
            if (month < 1) {
                lastMonthDate = `${year - 1}-12`;
            } else {
                // 月份小于10则补0
                if (month < 10) {
                    month = `0${month}`;
                }
                lastMonthDate = `${year}-${month}`;
            }
            return lastMonthDate;
        },
        onQueryGoodsVisitLog(queryData) {
            this.$api.St.GoodsSaleStat.findGoodsVisitLogList({
                ...this.queryFormModel,
                ...queryData,
                orderByKey: 'uv',
                orderByType: 'desc',
            }).then(json => {
                const res = json.data;
                this.goodsVisitLogList = res.data;
                this.goodsVisitLogPage = res.pagination;
            });
        },
        onQueryGoodsSaleStat(queryData) {
            this.$api.St.GoodsSaleStat.data({
                ...this.queryFormModel,
                ...queryData,
                orderByKey: 'orderGoodsNum',
                orderByType: 'desc',
            }).then(json => {
                const res = json.data;
                this.goodsSaleStatList = res.data;
                this.goodsSaleStatPage = res.pagination;
            });
        },
        onQueryStoreSaleStat(queryData) {
            this.$api.Stat.MarketingStat.storeMarketingStatList({
                ...queryData,
                type: '3',
                orderByKey: '1',
                orderByType: '1',
            }).then(json => {
                const res = json.data;
                this.storeSaleStatList = res.data;
            });
        },
    },
    created() {
        this.$nextTick(() => {
            this.init();
        });
    },
};
</script>

<style lang="scss">
.echarts-demo {
    width: 100%;
}
</style>
