<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page>
        <!-- 表单区 -->
        <template slot="secondHeader">
            <el-form
                ref="queryForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="queryFormModel"
            >
                <el-form-item
                    prop="emailUseCaseName"
                    label="邮件使用场景"
                >
                    <el-input
                        v-model="queryFormModel.emailUseCaseName"
                        placeholder="请输入邮件使用场景"
                    />
                </el-form-item>
                <el-form-item
                    prop="emailTemplateName"
                    label="邮件模板名称"
                >
                    <el-input
                        v-model="queryFormModel.emailTemplateName"
                        placeholder="请输入邮件模板名称"
                    />
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onQuery()"
                    >查询
                    </el-button>
                    <el-button
                        type="success"
                        @click="onReset"
                    >重置
                    </el-button>
                </el-form-item>
            </el-form>
        </template>

        <!-- 内容 -->
        <div
            class="body"
            slot="body"
            v-loading="querying"
        >
            <!-- 表格 -->
            <el-table
                stripe
                border
                size="small"
                :data="tableData"
                style="width: 100%"
            >
                <el-table-column
                    prop="emailUseCaseName"
                    label="邮件使用场景"
                    min-width="100"
                />
                <el-table-column
                    prop="emailTemplateName"
                    label="邮件模板名称"
                    min-width="100"
                />
                <el-table-column
                    prop="emailTitle"
                    label="邮件标题"
                    min-width="100"
                />
                <el-table-column
                    label="是否启用"
                    width="80"
                >
                    <template slot-scope="scope">
                        <el-switch
                            v-model="scope.row.isAvailable"
                            @input="onIsAvailableInput($event,scope)"
                            disabled
                            v-if="!scope.row.id"
                        />
                        <el-switch
                            v-model="scope.row.isAvailable"
                            @input="onIsAvailableInput($event,scope)"
                            v-if="scope.row.id"
                        />
                    </template>
                </el-table-column>
                <el-table-column
                    fixed="right"
                    label="操作"
                    width="90"
                >
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            size="small"
                            @click="onTableEdit(scope.row)"
                        >编辑
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>

            <!-- 分页 -->
            <div class="ma-t ta-r">
                <pagination
                    v-model="pagination"
                    @input="onQuery"
                />
            </div>
        </div>

        <!-- 弹框 -->
        <!-- 新增/编辑 -->
        <el-dialog
            :title="'编辑'"
            center
            width="1200px"
            :visible.sync="addDialog.isVisible"
            @closed="onAddDialogCancel"
        >
            <el-form
                ref="addForm"
                size="small"
                :model="addDialog.formModel"
                :rules="addDialog.formRules"
            >
                <el-form-item
                    prop="emailAccountId"
                    label="发送邮箱"
                    label-width="8em"
                >
                    <el-select
                        v-model="addDialog.formModel.emailAccountId"
                        placeholder="请选择"
                    >
                        <el-option
                            v-for="item in emailAccountList"
                            :key="item.id"
                            :label="item.emailAddress"
                            :value="item.id + ''"/>
                    </el-select>
                </el-form-item>
                <el-form-item
                    prop="emailUseCaseName"
                    label="邮件使用场景"
                    label-width="8em"
                >
                    <el-input
                        v-model="addDialog.formModel.emailUseCaseName"
                        auto-complete="off"
                        disabled=""
                    />
                </el-form-item>
                <el-form-item
                    prop="emailTemplateName"
                    label="邮件模板名称"
                    label-width="8em"
                >
                    <el-input
                        v-model="addDialog.formModel.emailTemplateName"
                        auto-complete="off"
                    />
                </el-form-item>
                <el-form-item
                    prop="emailTemplateDesc"
                    label="邮件模板描述"
                    label-width="8em"
                >
                    <el-input
                        type="textarea"
                        v-model="addDialog.formModel.emailTemplateDesc"
                        auto-complete="off"
                    />
                </el-form-item>
                <el-form-item
                    prop="emailTitle"
                    label="邮件标题"
                    label-width="8em"
                >
                    <el-input
                        v-model="addDialog.formModel.emailTitle"
                        auto-complete="off"
                    />
                </el-form-item>
                <el-form-item
                    prop="emailContent"
                    label="邮件内容"
                    label-width="8em"
                >
                    <Tinymce
                        :height="500"
                        image-action="/rs/attachment/uploadCmsArticleImage"
                        v-model="addDialog.formModel.emailContent"/>
                </el-form-item>
            </el-form>
            <div
                slot="footer"
                class="dialog-footer">
                <el-button
                    type="primary"
                    size="small"
                    @click="onAddDialogConfirm"
                >确 定
                </el-button>
                <el-button
                    size="small"
                    @click="onAddDialogCancel"
                >取 消
                </el-button>
            </div>
        </el-dialog>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';
import { queryFormList } from 'vmf/src/mixins';

export default {
    name: 'EmailTemplate',
    mixins: [pagesMixin, queryFormList],
    data() {
        return {
            queryFormModel: {
                orgId: this.$route.params.id, // 组织机构ID
                emailUseCaseName: '', // 邮件使用场景
                emailTemplateName: '', // 邮件模板名称
                isAvailable: '', // 状态
            },
            // 新增/编辑
            addDialog: {
                // 对话框显示
                isVisible: false,
                // 表单数据
                formModel: {
                    id: '',
                    orgId: this.$route.params.id, // 组织机构ID
                    emailAccountId: '',
                    emailUseCase: '',
                    emailTemplateName: '',
                    emailTemplateDesc: '',
                    emailTitle: '',
                    emailContent: '',
                    emailAttachment: '',
                    isAvailable: '',
                    emailUseCaseName: '',
                },
                // 表单校验规则
                formRules: {
                    emailAccountId: {
                        required: true,
                        message: '请选择发送邮箱',
                    },
                    emailTemplateName: {
                        required: true,
                        message: '请输入邮件模板名称',
                        trigger: 'blur',
                    },
                },
            },
            // 表格数据
            tableData: [],
            // 表格当前选中行
            currentTableSelect: [],
            // 发送邮箱列表
            emailAccountList: [],
        };
    },
    methods: {
        /* -- 方法 -- */
        // 请求列表数据
        getListData(queryData) {
            return this.$api.Ms.EmailTemplate.data(queryData).then(json => {
                const res = json.data;
                this.tableData = res.data;
                this.pagination = res.pagination;
                return Promise.resolve(json);
            });
        },
        /* -- 事件 -- */
        onAdd() {
            this.addDialog.isVisible = true;
            this.$nextTick(() => {
                const { addForm } = this.$refs;
                addForm.resetFields();
                const { formModel } = this.addDialog;
                formModel.id = '';
            });
        },
        onTableSelectionChange(val) {
            this.currentTableSelect = val;
        },
        onTableEdit(row) {
            this.addDialog.isVisible = true;
            if (row.id == null) {
                this.$nextTick(() => {
                    const { addForm } = this.$refs;
                    addForm.resetFields();
                    const { formModel } = this.addDialog;
                    formModel.id = '';
                    formModel.orgId = this.$route.params.id;
                    formModel.emailUseCase = row.entryKey;
                    formModel.emailUseCaseName = row.emailUseCaseName;
                    formModel.isAvailable = true;
                });
            } else {
                const { formModel } = this.addDialog;
                this.$api.Ms.EmailTemplate.getDetail({ id: row.id }).then(json => {
                    const res = json.data.data;
                    res.emailAccountId += '';
                    this.$utils.formModelMerge(formModel, res);
                });
            }
        },
        onAddDialogConfirm() {
            const { addForm } = this.$refs;
            addForm.validate().then(() => {
                this.$api.Ms.EmailTemplate.save({
                    ...this.addDialog.formModel,
                }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.onQuery(this.pagination);
                    this.addDialog.isVisible = false;
                });
            });
        },
        onAddDialogCancel() {
            const { addForm } = this.$refs;
            addForm.resetFields();
            const { formModel } = this.addDialog;
            formModel.id = '';
            this.addDialog.isVisible = false;
        },
        onIsAvailableInput(val, scope) {
            const { row } = scope;
            if (!row) return;
            this.$api.Ms.EmailTemplate.status({
                id: row.id,
                isAvailable: row.isAvailable,
            })
                .then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                })
                .catch(() => {
                    this.$message({
                        message: '操作失败请重试！',
                        type: 'error',
                    });
                    row.isAvailable = !row.isAvailable;
                });
        },
        initEmailAccountList() {
            this.$api.Ms.EmailAccount.select({ orgId: this.queryFormModel.orgId }).then(json => {
                const res = json.data.data;
                this.emailAccountList = res;
            });
        },
    },
    created() {
        this.initEmailAccountList();
    },
};
</script>

<style lang="scss">
</style>
