<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page>
        <!-- 表单区 -->
        <template slot="secondHeader">
            <el-tabs
                class="classify-tabs"
                v-model="queryFormModel.type"
                type="card"
                @tab-click="onQuery()"
            >
                <el-tab-pane
                    label="实时"
                    name="1"
                />
                <el-tab-pane
                    label="天视图"
                    name="2"
                />
                <el-tab-pane
                    label="周视图"
                    name="3"
                />
                <el-tab-pane
                    label="月视图"
                    name="4"
                />
            </el-tabs>
            <el-form
                ref="queryForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="queryFormModel"
            >
                <el-form-item
                    prop="statDateTime"
                    label="统计时间"
                    v-show="queryFormModel.type == 1"
                    key="statDateTime"
                >
                    <el-time-picker
                        is-range
                        v-model="queryFormModel.statDateTime"
                        format="HH:mm:ss"
                        value-format="HH:mm:ss"
                        range-separator="至"
                        start-placeholder="开始时间"
                        end-placeholder="结束时间"
                        placeholder="选择时间范围">
                    </el-time-picker>
                </el-form-item>
                <el-form-item
                    prop="statDateDay"
                    label="统计时间"
                    v-show="queryFormModel.type == 2"
                    key="statDateDay"
                >
                    <el-date-picker
                        :picker-options="pickerOptions"
                        v-model="queryFormModel.statDateDay"
                        type="daterange"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                    />
                </el-form-item>
                <el-form-item
                    prop="statDateWeek"
                    label="统计时间"
                    v-show="queryFormModel.type == 3"
                    key="statDateWeek"
                >
                    <el-date-picker
                        :picker-options="pickerOptions1"
                        v-model="queryFormModel.statDateWeek"
                        type="week"
                        format="yyyy-WW"
                        placeholder="选择周">
                    </el-date-picker>
                </el-form-item>
                <el-form-item
                    prop="statDateMonth"
                    label="统计时间"
                    v-show="queryFormModel.type == 4"
                    key="statDateMonth"
                >
                    <el-date-picker
                        :picker-options="pickerOptions2"
                        v-model="queryFormModel.statDateMonth"
                        type="monthrange"
                        format="yyyy-MM"
                        value-format="yyyy-MM"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                    />
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onQuery()"
                    >
                        查询
                    </el-button>
                    <el-button
                        type="success"
                        @click="onReset"
                    >
                        重置
                    </el-button>
                </el-form-item>
            </el-form>
        </template>

        <!-- 内容 -->
        <div
            class="body"
            slot="body"
            v-loading="querying"
        >
            <!-- 表格 -->
            <el-table
                stripe
                border
                size="small"
                :data="tableData"
                style="width: 100%"
            >
                <el-table-column
                    type="index"
                    width="50"
                />
                <el-table-column
                    prop="flowSourceName"
                    label="流量来源"
                    min-width="100"
                />
                <el-table-column
                    prop="orderAmount"
                    label="支付金额"
                    min-width="50"
                />
                <el-table-column
                    prop="uv"
                    label="uv"
                    min-width="50"
                />
                <el-table-column
                    prop="pv"
                    label="pv"
                    min-width="50"
                />
                <el-table-column
                    prop="customerPrice"
                    label="客单价"
                    min-width="50"
                />
                <el-table-column
                    prop="orderGoodsNum"
                    label="支付件数"
                    min-width="50"
                />
                <el-table-column
                    prop="orderNum"
                    label="支付订单数"
                    min-width="50"
                />
                <el-table-column
                    prop="orderBuyerNum"
                    label="支付买家数"
                    min-width="50"
                />
                <el-table-column
                    prop="conversionRate"
                    label="转化率"
                    min-width="50"
                >
                    <template slot-scope="scope">
                        {{scope.row.conversionRate}}%
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';
import { queryFormList } from 'vmf/src/mixins';

export default {
    name: 'GoodsSaleStat',
    mixins: [pagesMixin, queryFormList],
    data() {
        return {
            queryFormModel: {
                type: '1',
                statDateTime: '',
                statDateDay: [],
                statDateWeek: '',
                statDateMonth: [],
            },
            // 表格数据
            tableData: [],
            pickerOptions: {
                shortcuts: [
                    {
                        text: '最近一周',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 8);
                            end.setTime(end.getTime() - 3600 * 1000 * 24 * 1);
                            picker.$emit('pick', [start, end]);
                        },
                    },
                    {
                        text: '最近一个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 31);
                            end.setTime(end.getTime() - 3600 * 1000 * 24 * 1);
                            picker.$emit('pick', [start, end]);
                        },
                    },
                    {
                        text: '最近三个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 91);
                            end.setTime(end.getTime() - 3600 * 1000 * 24 * 1);
                            picker.$emit('pick', [start, end]);
                        },
                    },
                ],
                disabledDate: this.disabledDate,
            },
            pickerOptions1: {
                disabledDate: this.disabledDate1,
                firstDayOfWeek: 1,
            },
            pickerOptions2: {
                disabledDate: this.disabledDate2,
            },
        };
    },
    methods: {
        /* -- 方法 -- */
        // 请求列表数据
        getListData(queryData) {
            return this.$api.St.GoodsFlowSaleStat.data(queryData).then(json => {
                const res = json.data;
                this.tableData = res.data;
                return Promise.resolve(json);
            });
        },
        disabledDate(date) {
            return new Date().getTime() < new Date(date).getTime() + 3600 * 1000 * 24;
        },
        disabledDate1(date) {
            const week = new Date().getDay();
            const minus = week ? week - 1 : 6;
            return new Date().getTime() < new Date(date).getTime() + 3600 * 1000 * 24 * (minus + 1);
        },
        disabledDate2(date) {
            const nowDate = new Date();
            const dt = new Date(date);
            const year = `${nowDate.getFullYear()}}`;
            const month = `${nowDate.getMonth()}`.padStart(2, '0');
            const year1 = `${dt.getFullYear()}}`;
            const month1 = `${dt.getMonth()}`.padStart(2, '0');
            return `${year}${month}` <= `${year1}${month1}`;
        },
        exportExcel() {
            let statBeginDate = null;
            let statEndDate = null;
            if (this.queryFormModel.statDateDay != null && this.queryFormModel.statDateDay.length === 2) {
                [statBeginDate, statEndDate] = this.queryFormModel.statDateDay;
            }
            this.$http.href('/stat/goodsStat/exportExcel', {
                ...this.queryFormModel,
                statBeginDate,
                statEndDate,
            });
        },
    },
};
</script>

<style lang="scss">
.echarts-demo {
    width: 100%;
}
</style>
