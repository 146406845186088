<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page>
        <!-- 表单区 -->
        <template slot="secondHeader">
            <el-tabs
                class="classify-tabs"
                v-model="queryFormModel.type"
                type="card"
                @tab-click="init()"
            >
                <!--                <el-tab-pane-->
                <!--                    label="实时"-->
                <!--                    name="1"-->
                <!--                />-->
                <el-tab-pane
                    label="天视图"
                    name="2"
                />
                <el-tab-pane
                    label="周视图"
                    name="3"
                />
                <el-tab-pane
                    label="月视图"
                    name="4"
                />
            </el-tabs>
            <el-form
                ref="queryForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="queryFormModel"
                :rules="queryFormRule"
            >
                <el-form-item
                    prop="statDateTime"
                    label="统计时间"
                    v-if="queryFormModel.type == 1"
                    key="statDateTime"
                >
                    <el-time-picker
                        is-range
                        v-model="queryFormModel.statDateTime"
                        format="HH:mm:ss"
                        value-format="HH:mm:ss"
                        range-separator="至"
                        start-placeholder="开始时间"
                        end-placeholder="结束时间"
                        placeholder="选择时间范围">
                    </el-time-picker>
                </el-form-item>
                <el-form-item
                    prop="statDateDay"
                    label="统计时间"
                    v-if="queryFormModel.type == 2"
                    key="statDateDay"
                >
                    <el-date-picker
                        :picker-options="pickerOptions"
                        v-model="queryFormModel.statDateDay"
                        type="daterange"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                    />
                </el-form-item>
                <el-form-item
                    prop="statDateWeek"
                    label="统计时间"
                    v-if="queryFormModel.type == 3"
                    key="statDateWeek"
                >
                    <el-date-picker
                        :picker-options="pickerOptions1"
                        v-model="queryFormModel.statDateWeek"
                        type="week"
                        format="yyyy-WW"
                        placeholder="选择周">
                    </el-date-picker>
                </el-form-item>
                <el-form-item
                    prop="statDateMonth"
                    label="统计时间"
                    v-if="queryFormModel.type == 4"
                    key="statDateMonth"
                >
                    <el-date-picker
                        :picker-options="pickerOptions2"
                        v-model="queryFormModel.statDateMonth"
                        type="monthrange"
                        format="yyyy-MM"
                        value-format="yyyy-MM"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                    />
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="init()"
                    >
                        查询
                    </el-button>
                </el-form-item>
            </el-form>
        </template>

        <!-- 内容 -->
        <div
            class="body"
            slot="body"
        >
            <el-card
                class="ma-b"
                size="small"
                shadow="never"
            >
                <div
                    slot="header"
                    class="clearfix"
                >
                    <div class="dp-fx fx-row fx-ai-c">
                        <span>整体情况</span>
                        <el-popover
                            class="ma-l"
                            placement="top-start"
                            width="800"
                            trigger="hover">
                            <div slot>
                                昨日数据将在次日2：00左右更新完成
                                <br>
                                时间粒度：日数据仅单日人数去重，跨天加和不去重，周数据仅单周人数去重，跨周加和不去重，月数据仅单月人数去重，跨月不去重
                                <br>
                                实收金额：选定条件下，店铺中产生的已成功支付订单，在扣除优惠减免金额及优惠抵扣金额后，客户实际支付的金额，包含运费、税费（按支付成功时间）
                                <br>
                                浏览量：选定条件下，用户在商城店铺内打开页面的次数（按访问时间）
                                <br>
                                访客量：选定条件下，进入商城店铺的去重用户数（按访问时间）
                                <br>
                                访问支付转化率：选定条件下，从访问店铺到支付订单的转化率（支付人数/访客量）
                                <br>
                                客单价：选定条件下，平均每个客户支付的金额（应收金额/支付人数）
                                <br>
                                支付单数：选定条件下，店铺中产生的已成功支付订单单数（按支付成功时间）
                                <br>
                                支付人数：选定条件下，在店铺中有过支付行为的去重人数（按支付成功时间）
                                <br>
                                复购客户数：选定条件下，在店铺支付的客户中，非首次支付的去重人数（按支付成功时间）
                                <br>
                                支付会员数：选定条件下，在店铺支付的去重会员数（按支付成功时间）
                                <br>
                                售后成功金额：选定条件下，在店铺内售后成功的金额（只有金额，无积分余额）（按售后成功时间）
                                <br>
                                新增客户数：选定条件下，店铺中新增客户的数量
                            </div>
                            <i
                                class="el-icon-warning-outline"
                                slot="reference"></i>
                        </el-popover>
                    </div>
                </div>
                <el-row
                    justify="space-between"
                >
                    <el-col
                        :span="4"
                    >
                        <MeasureBox
                            :dataset="{
                                label:'实收金额',
                                value:shopSaleStat.orderAmount,
                                chainValue:shopSaleStat.orderAmountChain
                            }"
                        />
                    </el-col>
                    <el-col
                        :span="4"
                    >
                        <MeasureBox
                            :dataset="{
                                label:'浏览量',
                                value:shopSaleStat.pv,
                                chainValue:shopSaleStat.pvChain
                            }"
                        />
                    </el-col>
                    <el-col
                        :span="4"
                    >
                        <MeasureBox
                            :dataset="{
                                label:'访客量',
                                value:shopSaleStat.uv,
                                chainValue:shopSaleStat.uvChain
                            }"
                        />
                    </el-col>
                    <el-col
                        :span="4"
                    >
                        <MeasureBox
                            :dataset="{
                                label:'访问支付转化率',
                                value:shopSaleStat.visitPaymentConversionRate,
                                chainValue:shopSaleStat.visitPaymentConversionRateChain
                            }"
                        />
                    </el-col>
                    <el-col
                        :span="4"
                    >
                        <MeasureBox
                            :dataset="{
                                label:'客单价',
                                value:shopSaleStat.customerUnitPrice,
                                chainValue:shopSaleStat.customerUnitPriceChain
                            }"
                        />
                    </el-col>
                    <el-col
                        :span="4"
                    >
                        <MeasureBox
                            :dataset="{
                                label:'支付单数',
                                value:shopSaleStat.orderNum,
                                chainValue:shopSaleStat.orderNumChain
                            }"
                        />
                    </el-col>
                    <el-col
                        :span="4"
                    >
                        <MeasureBox
                            :dataset="{
                                label:'支付人数',
                                value:shopSaleStat.orderBuyerNum,
                                chainValue:shopSaleStat.orderBuyerNumChain
                            }"
                        />
                    </el-col>
                    <el-col
                        :span="4"
                    >
                        <MeasureBox
                            :dataset="{
                                label:'复购客户数',
                                value:shopSaleStat.repurchaseBuyerNum,
                                chainValue:shopSaleStat.repurchaseBuyerNumChain
                            }"
                        />
                    </el-col>
                    <el-col
                        :span="4"
                    >
                        <MeasureBox
                            :dataset="{
                                label:'售后成功金额',
                                value:shopSaleStat.refundAmount,
                                chainValue:shopSaleStat.refundAmountChain
                            }"
                        />
                    </el-col>
                    <el-col
                        :span="4"
                    >
                        <MeasureBox
                            :dataset="{
                                label:'新增客户数',
                                value:shopSaleStat.registerBuyerNum,
                                chainValue:shopSaleStat.registerBuyerNumChain
                            }"
                        />
                    </el-col>
                </el-row>
            </el-card>
            <el-card
                class="ma-b"
                size="small"
                shadow="never"
            >
                <div
                    slot="header"
                    class="clearfix"
                >
                    <div class="dp-fx fx-row fx-ai-c">
                        <span>趋势分析</span>
                    </div>
                </div>
                <ECharts
                    class="echarts-demo"
                    :options="echartsOptions1"
                />
            </el-card>
            <el-card
                class="ma-b"
                size="small"
                shadow="never"
            >
                <div
                    slot="header"
                    class="clearfix"
                >
                    <div class="dp-fx fx-row fx-ai-c">
                        <span>转化</span>
                        <el-popover
                            class="ma-l"
                            placement="top-start"
                            width="800"
                            trigger="hover">
                            <div slot>
                                访问加购转化率：选定条件下，加购商品的人数/访客量
                                <br>
                                访问支付转化率：选定条件下，支付人数/访客量
                                <br>
                                下单支付转化率：选定条件下，支付人数/下单人数
                                <br>
                                客户复购率：选定条件下，复购人数/支付人数
                            </div>
                            <i
                                class="el-icon-warning-outline"
                                slot="reference"></i>
                        </el-popover>
                    </div>
                </div>
                <el-row
                    justify="space-between"
                    :gutter="4"
                >
                    <el-col
                        :span="6"
                    >
                        <MeasureBox
                            :dataset="{
                                label:'访问加购转化率',
                                value:shopSaleStat.visitAddOnConversionRate,
                                chainValue:shopSaleStat.visitAddOnConversionRateChain
                            }"
                        />
                    </el-col>
                    <el-col
                        :span="6"
                    >
                        <MeasureBox
                            :dataset="{
                                label:'访问支付转化率',
                                value:shopSaleStat.visitPaymentConversionRate,
                                chainValue:shopSaleStat.visitPaymentConversionRateChain
                            }"
                        />
                    </el-col>
                    <el-col
                        :span="6"
                    >
                        <MeasureBox
                            :dataset="{
                                label:'下单支付转化率',
                                value:shopSaleStat.orderPaymentConversionRate,
                                chainValue:shopSaleStat.orderPaymentConversionRateChain
                            }"
                        />
                    </el-col>
                    <el-col
                        :span="6"
                    >
                        <MeasureBox
                            :dataset="{
                                label:'客户复购率',
                                value:shopSaleStat.customerRepurchaseRate,
                                chainValue:shopSaleStat.customerRepurchaseRateChain
                            }"
                        />
                    </el-col>
                    <el-col
                        :span="24"
                    >
                        <el-divider></el-divider>
                    </el-col>
                    <el-col
                        :span="12"
                    >
                        商品访问排行
                        <el-table
                            stripe
                            border
                            size="small"
                            :data="goodsVisitLogList"
                            style="width: 100%"
                        >
                            <el-table-column
                                prop="goodsSn"
                                label="商品编码"
                                min-width="100"
                            />
                            <el-table-column
                                prop="goodsName"
                                label="商品名称"
                                min-width="100"
                            />
                            <el-table-column
                                prop="uv"
                                label="访客量"
                                min-width="50"
                            />
                        </el-table>
                        <div class="ma-t ta-r">
                            <pagination
                                v-model="goodsVisitLogPage"
                                @input="onQueryGoodsVisitLog"
                            />
                        </div>
                    </el-col>
                    <el-col
                        :span="12"
                    >
                        商品加购排行
                        <el-table
                            stripe
                            border
                            size="small"
                            :data="goodsAddCartLogList"
                            style="width: 100%"
                        >
                            <el-table-column
                                prop="goodsSn"
                                label="商品编码"
                                min-width="100"
                            />
                            <el-table-column
                                prop="goodsName"
                                label="商品名称"
                                min-width="100"
                            />
                            <el-table-column
                                prop="addCartGoodsNum"
                                label="加购件数"
                                min-width="50"
                            />
                        </el-table>
                        <div class="ma-t ta-r">
                            <pagination
                                v-model="goodsAddCartLogPage"
                                @input="onQueryGoodsAddCartLog"
                            />
                        </div>
                    </el-col>
                    <el-col
                        :span="24"
                    >
                        <el-divider></el-divider>
                    </el-col>
                    <el-col
                        :span="12"
                    >
                        商品支付件数排行
                        <el-table
                            stripe
                            border
                            size="small"
                            :data="goodsPayNumLogList"
                            style="width: 100%"
                        >
                            <el-table-column
                                prop="goodsSn"
                                label="商品编码"
                                min-width="100"
                            />
                            <el-table-column
                                prop="goodsName"
                                label="商品名称"
                                min-width="100"
                            />
                            <el-table-column
                                prop="orderGoodsNum"
                                label="支付件数"
                                min-width="50"
                            />
                        </el-table>
                        <div class="ma-t ta-r">
                            <pagination
                                v-model="goodsPayNumLogPage"
                                @input="onQueryGoodsPayNumLog"
                            />
                        </div>
                    </el-col>
                    <el-col
                        :span="12"
                    >
                        商品应收金额排行
                        <el-table
                            stripe
                            border
                            size="small"
                            :data="goodsPayAmountLogList"
                            style="width: 100%"
                        >
                            <el-table-column
                                prop="goodsSn"
                                label="商品编码"
                                min-width="100"
                            />
                            <el-table-column
                                prop="goodsName"
                                label="商品名称"
                                min-width="100"
                            />
                            <el-table-column
                                prop="orderAmount"
                                label="应收金额"
                                min-width="50"
                            />
                        </el-table>
                        <div class="ma-t ta-r">
                            <pagination
                                v-model="goodsPayAmountLogPage"
                                @input="onQueryGoodsPayAmountLog"
                            />
                        </div>
                    </el-col>
                </el-row>
            </el-card>
            <el-card
                class="ma-b"
                size="small"
                shadow="never"
            >
                <div
                    slot="header"
                    class="clearfix"
                >
                    <div class="dp-fx fx-row fx-ai-c">
                        <span>流量</span>
                        <el-popover
                            class="ma-l"
                            placement="top-start"
                            width="800"
                            trigger="hover">
                            <div slot>
                                访问次数：选定条件下，所有客户访问店铺的次数。访客从进入到访问n个页面后最终关闭页面离开，整个过程计为1次；或在某页面停留10分钟不刷新也算离开，也计为1次
                                <br>
                                搜索词：选定条件下，店铺的搜索词对应的搜索人数
                                <br>
                                二级来源：进入店铺的场景值入口
                            </div>
                            <i
                                class="el-icon-warning-outline"
                                slot="reference"></i>
                        </el-popover>
                    </div>
                </div>
                <el-row
                    justify="space-between"
                >
                    <el-col
                        :span="4"
                    >
                        <MeasureBox
                            :dataset="{
                                label:'访问次数',
                                value:shopSaleStat.shopVisitNum,
                                chainValue:shopSaleStat.shopVisitNumChain
                            }"
                        />
                    </el-col>
                    <el-col
                        :span="24"
                    >
                        <el-divider></el-divider>
                    </el-col>
                    <el-col
                        :span="12"
                    >
                        <ECharts
                            class="echarts-demo"
                            :options="echartsOptions2"
                        />
                    </el-col>
                    <el-col
                        :span="12"
                    >
                        热搜词排行
                        <el-table
                            stripe
                            border
                            size="small"
                            :data="searchKeywordsSaleStatList"
                            style="width: 100%"
                        >
                            <el-table-column
                                prop="searchKeywords"
                                label="关键词"
                                min-width="100"
                            />
                            <el-table-column
                                prop="searchNum"
                                label="搜索次数"
                                min-width="100"
                            />
                        </el-table>
                        <div class="ma-t ta-r">
                            <pagination
                                v-model="searchKeywordsSaleStatPage"
                                @input="onQuerySearchKeywordsSaleStat"
                            />
                        </div>
                    </el-col>
                    <el-col
                        :span="24"
                    >
                        <el-divider></el-divider>
                    </el-col>
                    <el-col
                        :span="24"
                    >
                        访问来源排行
                        <el-table
                            stripe
                            border
                            size="small"
                            :data="goodsFlowSaleStatList"
                            style="width: 100%"
                        >
                            <el-table-column
                                prop="flowSourceName"
                                label="访问日志来源"
                                min-width="100"
                            />
                            <el-table-column
                                prop="uv"
                                label="访客量"
                                min-width="100"
                            />
                            <el-table-column
                                prop="confirmOrderArrivalRate"
                                label="确认订单到达率"
                                min-width="100"
                            >
                                <template slot-scope="scope">
                                    {{ scope.row.confirmOrderArrivalRate }}%
                                </template>
                            </el-table-column>
                        </el-table>
                        <div class="ma-t ta-r">
                            <pagination
                                v-model="goodsFlowSaleStatPage"
                                @input="onQueryGoodsFlowSaleStat"
                            />
                        </div>
                    </el-col>
                </el-row>
            </el-card>
            <el-card
                class="ma-b"
                size="small"
                shadow="never"
            >
                <div
                    slot="header"
                    class="clearfix"
                >
                    <div class="dp-fx fx-row fx-ai-c">
                        <span>客单</span>
                        <el-popover
                            class="ma-l"
                            placement="top-start"
                            width="800"
                            trigger="hover">
                            <div slot>
                                客单价：选定条件下，平均每个客户支付的金额（应收金额/支付人数）
                                <br>
                                人均支付件数：选定条件下，平均每个客户支付的商品件数（支付件数/支付人数）
                                <br>
                                连带率：选定条件下，支付件数/支付单数
                                <br>
                                搭配人数：在选定条件下，商品共同出现在订单内共同出现的人数
                            </div>
                            <i
                                class="el-icon-warning-outline"
                                slot="reference"></i>
                        </el-popover>
                    </div>
                </div>
                <el-row
                    justify="space-between"
                >
                    <el-col
                        :span="8"
                    >
                        <MeasureBox
                            :dataset="{
                                label:'客单价',
                                value:shopSaleStat.customerUnitPrice,
                                chainValue:shopSaleStat.customerUnitPriceChain
                            }"
                        />
                    </el-col>
                    <el-col
                        :span="8"
                    >
                        <MeasureBox
                            :dataset="{
                                label:'人均支付件数',
                                value:shopSaleStat.numberOfPaymentsPerCapita,
                                chainValue:shopSaleStat.numberOfPaymentsPerCapitaChain
                            }"
                        />
                    </el-col>
                    <el-col
                        :span="8"
                    >
                        <MeasureBox
                            :dataset="{
                                label:'连带率',
                                value:shopSaleStat.associationRate,
                                chainValue:shopSaleStat.associationRateChain
                            }"
                        />
                    </el-col>
                    <el-col
                        :span="24"
                    >
                        <el-divider></el-divider>
                    </el-col>
                    <el-col
                        :span="24"
                    >
                        <ECharts
                            class="echarts-demo"
                            :options="echartsOptions3"
                        />
                    </el-col>
                    <el-col
                        :span="24"
                    >
                        <el-divider></el-divider>
                    </el-col>
                    <el-col
                        :span="24"
                    >
                        搭配推荐排行
                        <el-table
                            stripe
                            border
                            size="small"
                            :data="goodsMatchingSaleStatList"
                            style="width: 100%"
                        >
                            <el-table-column
                                prop="goodsSn"
                                label="商品编码"
                                min-width="100"
                            />
                            <el-table-column
                                prop="goodsName"
                                label="商品名称"
                                min-width="100"
                            />
                            <el-table-column
                                prop="matchingGoodsSn"
                                label="搭配商品编码"
                                min-width="100"
                            />
                            <el-table-column
                                prop="matchingGoodsName"
                                label="搭配商品名称"
                                min-width="100"
                            />
                            <el-table-column
                                prop="orderBuyerNum"
                                label="搭配人数"
                                min-width="50"
                            />
                        </el-table>
                        <div class="ma-t ta-r">
                            <pagination
                                v-model="goodsMatchingSaleStatPage"
                                @input="onQueryGoodsMatchingSaleStat"
                            />
                        </div>
                    </el-col>
                </el-row>
            </el-card>
            <el-card
                class="ma-b"
                size="small"
                shadow="never"
            >
                <div
                    slot="header"
                    class="clearfix"
                >
                    <div class="dp-fx fx-row fx-ai-c">
                        <span>客户</span>
                    </div>
                </div>
                <el-row
                    justify="space-between"
                >
                    <el-col
                        :span="8"
                    >
                        <MeasureBox
                            :dataset="{
                                label:'新增客户数',
                                value:shopSaleStat.registerBuyerNum,
                                chainValue:shopSaleStat.registerBuyerNumChain
                            }"
                        />
                    </el-col>
                    <el-col
                        :span="24"
                    >
                        <el-divider></el-divider>
                    </el-col>
                    <el-col
                        :span="12"
                    >
                        支付新老人数分布
                        <ECharts
                            class="echarts-demo"
                            :options="echartsOptions4"
                        />
                    </el-col>
                    <el-col
                        :span="12"
                    >
                        支付新老应收金额分布
                        <ECharts
                            class="echarts-demo"
                            :options="echartsOptions5"
                        />
                    </el-col>
                    <el-col
                        :span="24"
                    >
                        <el-divider></el-divider>
                    </el-col>
                    <el-col
                        :span="24"
                    >
                        消费榜
                        <el-table
                            stripe
                            border
                            size="small"
                            :data="buyerSaleStatList"
                            style="width: 100%"
                        >
                            <el-table-column
                                prop="userName"
                                label="账号"
                                min-width="100"
                            />
                            <el-table-column
                                prop="mobile"
                                label="手机号"
                                min-width="100"
                            />
                            <el-table-column
                                prop="levelName"
                                label="会员等级"
                                min-width="100"
                            />
                            <el-table-column
                                prop="orderAmount"
                                label="下单金额"
                                min-width="100"
                            />
                        </el-table>
                        <div class="ma-t ta-r">
                            <pagination
                                v-model="buyerSaleStatPage"
                                @input="onQueryBuyerSaleStat"
                            />
                        </div>
                    </el-col>
                </el-row>
            </el-card>
        </div>
    </page>
</template>

<script>
import MeasureBox from '@/components/MeasureBox';
import { queryFormList } from 'vmf/src/mixins';

export default {
    name: 'GoodsSaleStatIndex',
    components: { MeasureBox },
    mixins: [queryFormList],
    data() {
        return {
            queryFormModel: {
                type: '2',
                statDateTime: '',
                statDateDay: [],
                statDateWeek: '',
                statDateMonth: [],
            },
            queryFormRule: {
                statDateTime: {
                    required: true,
                    message: '请选择统计时间',
                },
                statDateDay: {
                    type: 'array',
                    required: true,
                    message: '请选择统计时间',
                },
                statDateWeek: {
                    required: true,
                    message: '请选择统计时间',
                },
                statDateMonth: {
                    type: 'array',
                    required: true,
                    message: '请选择统计时间',
                },
            },
            // 表格数据
            tableData: [],
            pickerOptions: {
                shortcuts: [
                    {
                        text: '最近一周',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 8);
                            end.setTime(end.getTime() - 3600 * 1000 * 24 * 1);
                            picker.$emit('pick', [start, end]);
                        },
                    },
                    {
                        text: '最近一个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 31);
                            end.setTime(end.getTime() - 3600 * 1000 * 24 * 1);
                            picker.$emit('pick', [start, end]);
                        },
                    },
                    {
                        text: '最近三个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 91);
                            end.setTime(end.getTime() - 3600 * 1000 * 24 * 1);
                            picker.$emit('pick', [start, end]);
                        },
                    },
                ],
                disabledDate: this.disabledDate,
            },
            pickerOptions1: {
                disabledDate: this.disabledDate1,
                firstDayOfWeek: 1,
            },
            pickerOptions2: {
                disabledDate: this.disabledDate2,
            },
            shopSaleStat: {},
            shopSaleStatList: [],
            goodsVisitLogList: [],
            goodsVisitLogPage: {},
            goodsAddCartLogList: [],
            goodsAddCartLogPage: {},
            goodsPayNumLogList: [],
            goodsPayNumLogPage: {},
            goodsPayAmountLogList: [],
            goodsPayAmountLogPage: {},
            browserTypeSaleStatList: [],
            searchKeywordsSaleStatList: [],
            searchKeywordsSaleStatPage: {},
            goodsFlowSaleStatList: [],
            goodsFlowSaleStatPage: {},
            goodsMatchingSaleStatList: [],
            goodsMatchingSaleStatPage: {},
            buyerSaleStatList: [],
            buyerSaleStatPage: {},
        };
    },
    computed: {
        echartsOptions1() {
            const data = {};
            const date = [];
            this.shopSaleStatList.forEach(item => {
                date.push(item.statDate);
                Object.keys(item).forEach(key => {
                    if (!data[key]) data[key] = [];
                    data[key].push(item[key]);
                });
            });
            return {
                tooltip: {
                    trigger: 'axis',
                },
                legend: {
                    data: [
                        '实收金额',
                        '浏览量',
                        '访客量',
                        '访问支付转化率',
                        '客单价',
                        '支付单数',
                        '支付人数',
                        '复购客户数',
                        '客户复购率',
                        '售后成功金额',
                        '新增客户数',
                    ],
                    selected: {
                        实收金额: true,
                        浏览量: false,
                        访客量: false,
                        访问支付转化率: false,
                        客单价: false,
                        支付单数: false,
                        支付人数: false,
                        复购客户数: false,
                        客户复购率: false,
                        售后成功金额: false,
                        新增客户数: false,
                    },
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true,
                },
                toolbox: {
                    feature: {
                        saveAsImage: {},
                    },
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: date,
                },
                yAxis: {
                    type: 'value',
                },
                series: [
                    {
                        name: '实收金额',
                        type: 'line',
                        data: data.orderAmount,
                    },
                    {
                        name: '浏览量',
                        type: 'line',
                        data: data.pv,
                    },
                    {
                        name: '访客量',
                        type: 'line',
                        data: data.uv,
                    },
                    {
                        name: '访问支付转化率',
                        type: 'line',
                        data: data.visitPaymentConversionRate,
                    },
                    {
                        name: '客单价',
                        type: 'line',
                        data: data.customerUnitPrice,
                    },
                    {
                        name: '支付单数',
                        type: 'line',
                        data: data.orderNum,
                    },
                    {
                        name: '支付人数',
                        type: 'line',
                        data: data.orderBuyerNum,
                    },
                    {
                        name: '复购客户数',
                        type: 'line',
                        data: data.repurchaseBuyerNum,
                    },
                    {
                        name: '客户复购率',
                        type: 'line',
                        data: data.customerRepurchaseRate,
                    },
                    {
                        name: '售后成功金额',
                        type: 'line',
                        data: data.refundAmount,
                    },
                    {
                        name: '新增客户数',
                        type: 'line',
                        data: data.registerBuyerNum,
                    },
                ],
            };
        },
        echartsOptions2() {
            const dataList = [];
            const title = [];
            this.browserTypeSaleStatList.forEach(item => {
                title.push(item.browserTypeName);
                dataList.push({ value: item.uv, name: item.browserTypeName });
            });
            return {
                title: {
                    text: '一级渠道访客占比',
                    left: 'center',
                },
                tooltip: {
                    trigger: 'item',
                    formatter: '{a} <br/>{b} : {c} ({d}%)',
                },
                legend: {
                    orient: 'vertical',
                    left: 'left',
                    data: title,
                },
                series: [
                    {
                        name: '访客占比',
                        type: 'pie',
                        radius: '55%',
                        center: ['50%', '60%'],
                        data: dataList,
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)',
                            },
                        },
                    },
                ],
            };
        },
        echartsOptions3() {
            return {
                tooltip: {
                    trigger: 'item',
                    formatter: '{a} <br/>{b}: {c} ({d}%)',
                },
                legend: {
                    orient: 'vertical',
                    left: 10,
                    data: ['1件', '2件', '3件', '4件', '5件及以上'],
                },
                series: [
                    {
                        name: '人均支付件数',
                        type: 'pie',
                        radius: ['50%', '70%'],
                        avoidLabelOverlap: false,
                        label: {
                            show: false,
                            position: 'center',
                        },
                        emphasis: {
                            label: {
                                show: true,
                                fontSize: '30',
                                fontWeight: 'bold',
                            },
                        },
                        labelLine: {
                            show: false,
                        },
                        data: [
                            { value: this.shopSaleStat.avgGoodsBuyerNum1, name: '1件' },
                            { value: this.shopSaleStat.avgGoodsBuyerNum2, name: '2件' },
                            { value: this.shopSaleStat.avgGoodsBuyerNum3, name: '3件' },
                            { value: this.shopSaleStat.avgGoodsBuyerNum4, name: '4件' },
                            { value: this.shopSaleStat.avgGoodsBuyerNum5, name: '5件及以上' },
                        ],
                    },
                ],
            };
        },
        echartsOptions4() {
            return {
                tooltip: {
                    trigger: 'item',
                    formatter: '{a} <br/>{b}: {c} ({d}%)',
                },
                legend: {
                    orient: 'vertical',
                    left: 10,
                    data: ['首购客户数', '复购客户数'],
                },
                series: [
                    {
                        name: '支付新老人数分布',
                        type: 'pie',
                        radius: ['50%', '70%'],
                        avoidLabelOverlap: false,
                        label: {
                            show: false,
                            position: 'center',
                        },
                        emphasis: {
                            label: {
                                show: true,
                                fontSize: '30',
                                fontWeight: 'bold',
                            },
                        },
                        labelLine: {
                            show: false,
                        },
                        data: [
                            { value: this.shopSaleStat.firstPurchaseBuyerNum, name: '首购客户数' },
                            { value: this.shopSaleStat.repurchaseBuyerNum, name: '复购客户数' },
                        ],
                    },
                ],
            };
        },
        echartsOptions5() {
            return {
                tooltip: {
                    trigger: 'item',
                    formatter: '{a} <br/>{b}: {c} ({d}%)',
                },
                legend: {
                    orient: 'vertical',
                    left: 10,
                    data: ['首购金额', '复购金额'],
                },
                series: [
                    {
                        name: '支付新老应收金额分布',
                        type: 'pie',
                        radius: ['50%', '70%'],
                        avoidLabelOverlap: false,
                        label: {
                            show: false,
                            position: 'center',
                        },
                        emphasis: {
                            label: {
                                show: true,
                                fontSize: '30',
                                fontWeight: 'bold',
                            },
                        },
                        labelLine: {
                            show: false,
                        },
                        data: [
                            { value: this.shopSaleStat.firstPurchaseOrderAmount, name: '首购金额' },
                            { value: this.shopSaleStat.repurchaseOrderAmount, name: '复购金额' },
                        ],
                    },
                ],
            };
        },
    },
    methods: {
        /* -- 方法 -- */
        // 请求列表数据
        getListData(queryData) {
            return this.$api.St.ShopSaleStat.data(queryData).then(json => {
                const res = json.data;
                this.tableData = res.data;
                return Promise.resolve(json);
            });
        },
        disabledDate(date) {
            return new Date().getTime() < new Date(date).getTime() + 3600 * 1000 * 24;
        },
        disabledDate1(date) {
            const week = new Date().getDay();
            const minus = week ? week - 1 : 6;
            return new Date().getTime() < new Date(date).getTime() + 3600 * 1000 * 24 * (minus + 1);
        },
        disabledDate2(date) {
            const nowDate = new Date();
            const dt = new Date(date);
            const year = `${nowDate.getFullYear()}}`;
            const month = `${nowDate.getMonth()}`.padStart(2, '0');
            const year1 = `${dt.getFullYear()}}`;
            const month1 = `${dt.getMonth()}`.padStart(2, '0');
            return `${year}${month}` <= `${year1}${month1}`;
        },
        init() {
            const { queryForm } = this.$refs;
            queryForm.validate().then(() => {
                this.$api.St.ShopSaleStat.statDetail({ ...this.queryFormModel }).then(json => {
                    const res = json.data.data;
                    this.shopSaleStat = res.shopSaleStat;
                });
                this.$api.St.ShopSaleStat.findStatDetailList({ ...this.queryFormModel }).then(json => {
                    const res = json.data.data;
                    this.shopSaleStatList = res.shopSaleStatList;
                });
                this.$api.St.BrowserTypeSaleStat.findStatDetailList({ ...this.queryFormModel }).then(json => {
                    const res = json.data.data;
                    this.browserTypeSaleStatList = res.browserTypeSaleStatList || [];
                });
                this.onQueryGoodsVisitLog();
                this.onQueryGoodsPayAmountLog();
                this.onQueryGoodsMatchingSaleStat();
                this.onQueryBuyerSaleStat();
                this.onQuerySearchKeywordsSaleStat();
                this.onQueryGoodsFlowSaleStat();
                this.onQueryGoodsPayNumLog();
                this.onQueryGoodsAddCartLog();
            });
        },
        getYeasterDay() {
            const time = new Date().getTime() - 24 * 60 * 60 * 1000;
            let yesterday = new Date(time);
            // const month = yesterday.getMonth();
            // const day = yesterday.getDate();
            yesterday = `${yesterday.getFullYear()}-${
                yesterday.getMonth() > 9 ? yesterday.getMonth() + 1 : `0${yesterday.getMonth() + 1}`
            }-${yesterday.getDate() > 9 ? yesterday.getDate() : `0${yesterday.getDate()}`}`;
            return yesterday;
        },
        getLastMonth() {
            const date = new Date();
            let lastMonthDate = '';
            let month = date.getMonth();
            const year = date.getFullYear();
            // 上个月小于1则证明跨年，年份减1
            if (month < 1) {
                lastMonthDate = `${year - 1}-12`;
            } else {
                // 月份小于10则补0
                if (month < 10) {
                    month = `0${month}`;
                }
                lastMonthDate = `${year}-${month}`;
            }
            return lastMonthDate;
        },
        onQueryGoodsVisitLog(queryData) {
            this.$api.St.GoodsSaleStat.data({
                ...this.queryFormModel,
                ...queryData,
                orderByKey: 'uv',
                orderByType: 'desc',
            }).then(json => {
                const res = json.data;
                this.goodsVisitLogList = res.data;
                this.goodsVisitLogPage = res.pagination;
            });
        },
        onQueryGoodsAddCartLog(queryData) {
            this.$api.St.GoodsSaleStat.data({
                ...this.queryFormModel,
                ...queryData,
                orderByKey: 'addCartGoodsNum',
                orderByType: 'desc',
            }).then(json => {
                const res = json.data;
                this.goodsAddCartLogList = res.data;
                this.goodsAddCartLogPage = res.pagination;
            });
        },
        onQueryGoodsPayNumLog(queryData) {
            this.$api.St.GoodsSaleStat.data({
                ...this.queryFormModel,
                ...queryData,
                orderByKey: 'orderGoodsNum',
                orderByType: 'desc',
            }).then(json => {
                const res = json.data;
                this.goodsPayNumLogList = res.data;
                this.goodsPayNumLogPage = res.pagination;
            });
        },
        onQueryGoodsPayAmountLog(queryData) {
            this.$api.St.GoodsSaleStat.data({
                ...this.queryFormModel,
                ...queryData,
                orderByKey: 'orderAmount',
                orderByType: 'desc',
            }).then(json => {
                const res = json.data;
                this.goodsPayAmountLogList = res.data;
                this.goodsPayAmountLogPage = res.pagination;
            });
        },
        onQuerySearchKeywordsSaleStat(queryData) {
            this.$api.St.SearchKeywordsSaleStat.data({
                ...this.queryFormModel,
                ...queryData,
            }).then(json => {
                const res = json.data;
                this.searchKeywordsSaleStatList = res.data;
                this.searchKeywordsSaleStatPage = res.pagination;
            });
        },
        onQueryGoodsFlowSaleStat(queryData) {
            this.$api.St.GoodsFlowSaleStat.data({
                ...this.queryFormModel,
                ...queryData,
            }).then(json => {
                const res = json.data;
                this.goodsFlowSaleStatList = res.data;
                this.goodsFlowSaleStatPage = res.pagination;
            });
        },
        onQueryGoodsMatchingSaleStat(queryData) {
            this.$api.St.GoodsMatchingSaleStat.data({
                ...this.queryFormModel,
                ...queryData,
            }).then(json => {
                const res = json.data;
                this.goodsMatchingSaleStatList = res.data;
                this.goodsMatchingSaleStatPage = res.pagination;
            });
        },
        onQueryBuyerSaleStat(queryData) {
            this.$api.St.BuyerSaleStat.data({
                ...this.queryFormModel,
                ...queryData,
            }).then(json => {
                const res = json.data;
                this.buyerSaleStatList = res.data;
                this.buyerSaleStatPage = res.pagination;
            });
        },
    },
    created() {
        this.queryFormModel.statDateDay = [this.getYeasterDay(), this.getYeasterDay()];
        this.queryFormModel.statDateWeek = new Date().setDate(new Date().getDate() - 7 - new Date().getDay() + 1);
        this.queryFormModel.statDateMonth = [this.getLastMonth(), this.getLastMonth()];

        // this.queryFormModel.type = '4';
        // this.queryFormModel.statDateMonth = ['2022-05', '2022-06'];
        this.$nextTick(() => {
            this.init();
        });
    },
};
</script>

<style lang="scss">
.echarts-demo {
    width: 100%;
}
</style>
