<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page back>
        <template
            slot="body"
        >
            <el-row>
                <el-col :span="24">
                    <el-form
                        ref="addForm"
                        size="small"
                        :model="formModel"
                        :rules="formRules"
                    >
                        <el-card
                            size="small"
                            shadow="never"
                            class="ma-t"
                        >
                            <div
                                slot="header"
                                class="clearfix"
                            >
                                <span>平台信息</span>
                            </div>
                            <div class="item">
                                <el-form-item
                                    prop="isSupplierQuotations"
                                    label="是否供应商库存报价"
                                    label-width="13em"
                                >
                                    <el-switch
                                        v-model="formModel.isSupplierQuotations"
                                    />
                                </el-form-item>
                            </div>
                        </el-card>
                        <el-card
                            size="small"
                            shadow="never"
                            class="ma-t"
                        >
                            <div
                                slot="header"
                                class="clearfix"
                            >
                                <span>第三方平台设置</span>
                            </div>
                            <div class="item">
                                <el-form-item
                                    prop="isPushInventory"
                                    label="是否推送进销存系统"
                                    label-width="13em"
                                >
                                    <el-switch
                                        v-model="formModel.isPushInventory"
                                    />
                                </el-form-item>
                                <el-form-item
                                    prop="isSyncInventoryGoods"
                                    label="是否同步进销存商品"
                                    label-width="13em"
                                    v-if="formModel.isPushInventory"
                                >
                                    <el-switch
                                        v-model="formModel.isSyncInventoryGoods"
                                    />
                                </el-form-item>
                                <el-form-item
                                    prop="inventoryAppKey"
                                    label="进销存系统AppKey"
                                    label-width="13em"
                                    v-if="formModel.isPushInventory"
                                >
                                    <el-input
                                        v-model="formModel.inventoryAppKey"
                                        auto-complete="off"
                                    />
                                </el-form-item>
                                <el-form-item
                                    prop="inventoryAppSecret"
                                    label="进销存系统AppSecret"
                                    label-width="13em"
                                    v-if="formModel.isPushInventory"
                                >
                                    <el-input
                                        v-model="formModel.inventoryAppSecret"
                                        auto-complete="off"
                                    />
                                </el-form-item>
                            </div>
                        </el-card>
                        <el-card
                            size="small"
                            shadow="never"
                            class="ma-t"
                        >
                            <div class="item">
                                <el-form-item
                                    label-width="13em"
                                >
                                    <el-button
                                        type="primary"
                                        size="small"
                                        @click="onConfirm"
                                    >
                                        保 存
                                    </el-button>
                                </el-form-item>
                            </div>
                        </el-card>
                    </el-form>
                </el-col>
            </el-row>
        </template>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';

export default {
    name: 'ShopEdit',
    mixins: [pagesMixin],
    data() {
        return {
            // 表单数据
            formModel: {
                isSupplierQuotations: true,

                isPushInventory: false,
                isSyncInventoryGoods: false,
                inventoryAppKey: '',
                inventoryAppSecret: '',
            },
            // 表单校验规则
            formRules: {},
        };
    },
    methods: {
        onConfirm() {
            const { addForm } = this.$refs;
            addForm.validate().then(() => {
                this.$api.Rs.PlatformConfig.save({
                    ...this.formModel,
                }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                });
            });
        },
        init() {
            this.$api.Rs.PlatformConfig.getDetail().then(json => {
                const res = json.data.data;
                this.$utils.formModelMerge(this.formModel, res);
            });
        },
    },
    created() {
        this.init();
    },
};
</script>

<style lang="scss">
</style>
